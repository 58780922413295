<template>
  <div
    class="post-grid-loader select-none opacity-50"
    role="progressbar"
    aria-busy="true"
    aria-valuemin="0"
    aria-valuemax="100"
    aria-valuetext="Ładowanie..."
    aria-label="Ładowanie..."
  >
    <div class="post-grid-loader__wrapper" aria-hidden="true">
      <div class="post-grid-loader__image">
        <AppSkeleton block class="pt-[61.803398875%]" />
      </div>
      <div class="post-grid-loader__content pt-1">
        <div
          class="post-grid-loader__header flex items-center justify-between gap-2"
        >
          <div class="post-grid-loader__header-title grow leading-[1.125rem]">
            <AppSkeleton class="w-2/3 h-[0.875rem]" />
          </div>
          <div class="post-grid-loader__header-tag leading-4 basis-1/4">
            <AppSkeleton class="w-full h-[0.875rem]" />
          </div>
        </div>

        <div class="post-grid-loader__subheader">
          <div class="post-grid-loeader__subheader-subtitle leading-[0.875rem]">
            <AppSkeleton class="w-1/3 h-[0.875rem]" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
